import React from 'react';
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import "./barChartBox.scss";

const CombinedBarChartBox = ({ title1, color1, dataKey1, chartData1, title2, color2, dataKey2, chartData2 }) => {

    const CustomTooltip = ({ active, payload, label, type }) => {
        if (active && payload && payload.length) {
            const value = parseFloat(payload[0].value); // Parse value as a float or number
            if (isNaN(value)) return null; // Handle cases where value is not a number

            return (
                <div className="custom-tooltip">
                    <p>{type}: {value.toFixed(2)} - {label}</p>
                </div>
            );
        }

        return null;
    };

    return (
        <div className="combinedBarChartBox">
            <div className="barChartBox">
                <h1 className="bar_chart_title">{title1}</h1>
                <div className="chart">
                    <ResponsiveContainer width="101%" height={130}>
                        <BarChart data={chartData1}>
                            <XAxis dataKey="country" />
                            <YAxis />
                            <Tooltip content={<CustomTooltip type="Earnings" />} />
                            <Bar dataKey={dataKey1} fill={color1} />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
            <div className="barChartBox">
                <h1 className="bar_chart_title">{title2}</h1>
                <div className="chart">
                    <ResponsiveContainer width="101%" height={130}>
                        <BarChart data={chartData2}>
                            <XAxis dataKey="country" />
                            <YAxis />
                            <Tooltip content={<CustomTooltip type="eCPM" />} />
                            <Bar dataKey={dataKey2} fill={color2} />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
    );
};

export default CombinedBarChartBox;
