// src/components/modal/Modal.js
import React from 'react';
import './topusers.scss';
import { FaGift } from 'react-icons/fa'; // Import gift icon from react-icons

const Modal = ({ isModalOpen, toggleModal, topUsers, username, userPosition }) => {
    return (
        <div className={`modal-container ${isModalOpen ? 'open' : ''}`}>
            <button
                aria-label="Poklon"
                onClick={toggleModal}
                className={`open-modal-arrow ${isModalOpen ? 'open' : ''}`}>
                <FaGift /> {/* React gift icon */}
            </button>

            <div className={`modal-content ${isModalOpen ? 'open' : ''}`}>
                <h2>Giveaway Rang Lista</h2>
                <a href="https://www.instagram.com/_balkanflix?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==">ig: @_balkanflix</a>
                <a href="https://www.tiktok.com/@balkanflix.official?is_from_webapp=1&sender_device=pc">tt: @balkanflix.official</a>
                <div className="modal-info">
                    {topUsers.length > 0 ? (
                        topUsers.map((topUser, index) => (
                            <div
                                key={topUser._id}
                                className={`user-info ${username === topUser._id ? "highlight" : ""} ${index === 0 ? "top-one" : index === 1 ? "top-two" : index === 2 ? "top-three" : ""}`}
                            >
                                <div className="user-rank">
                                    <span>{index + 1}.</span>
                                </div>
                                <img src={topUser.pfp} alt="Avatar korisnika" />
                                <div>
                                    <p className="username"><strong>{topUser._id}</strong></p>
                                    <p>Ukupno epizoda gledano: <strong>{topUser.totalEpisodesWatched}</strong></p>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>Nema pronađenih top korisnika</p>
                    )}
                    {userPosition && userPosition > 5 && (
                        <div className="position-info">
                            Vi ste rangirani #{userPosition} ukupno.
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Modal;