import {useEffect, useState} from "react"
import DataTable from "../dataTable/DataTable"
import AddUserBtn from "../../../Buttons/addUserBtn/addUser";
import AddUserForm from "../../../Buttons/addUserForm/addUserForm";
import axios from "axios";
import "./userspagedb.scss"

const Users = () => {
    const [open, setOpen] = useState(false);
    const [userRows, setUserRows] = useState([]);
    const [loading, setLoading] = useState(false);

    const columns = [
        { field: "id", headerName: "ID", width: 100 },
        {
            field: "title",
            type: "string",
            headerName: "Title",
            width: 120
        },
        {
            field: "email",
            type: "string",
            headerName: "Email",
            width: 230
        },
        {
            field: "createdAt",
            headerName: "Created At",
            width: 100,
            type: "string"
        },
        {
            field: "verified",
            headerName: "Verified",
            width: 70,
            type: "boolean"

        },
        {
            field: "action",
            headerName: "Action",
            width: 70,
            renderCell: (params) => {
                return (
                    <div className="action">
                        <div className="delete" onClick={() => getUser(params.row.id)}>
                            <img src="/Dashboard/delete.svg" alt="dash" />
                        </div>
                    </div>
                );
            },
        }
    ]

    const fetchUsers = async () => {
        try {
            const res = await axios.get('https://balkanflix-server.vercel.app/api/auth/allUsers');
            const usersWithId = res.data.data.map(user => ({
                ...user,
                id: user._id,
                verified: user.isVerified,
            }));
            setUserRows(usersWithId);
        } catch (error) {
            console.error("Failed to fetch users", error);
        }
    };

    const getUser = async (userId) => {
        setLoading(true);
        try{

            // const res = await axios.delete(`https://balkanflix-server.vercel.app/api/auth/deleteUser/${userId}`, {
            //     headers: {
            //         "Content-Type": "application/json",
            //     },
            // });
            await fetchUsers();

        } catch (e) {
            console.log(e);
        }
        setLoading(false);
    }

    const toggleModal = () => {
        setOpen(!open);
    };

    useEffect(() => {
        fetchUsers().then(result => console.log(result));
    }, []);

    return (
        <>
            {loading && (
                <div className="loading-modal">
                    <div className="loading-bg">
                        <div className="loading-spinner"></div>
                        <h1>Deleting a user...</h1>
                    </div>
                </div>
            )}
            <div className="users">
                <div className="info">
                    <h1>Users</h1>
                    <AddUserBtn product="User" onClick={toggleModal}/>
                    <button className="add-user-btn" onClick={fetchUsers}><h4>Refresh</h4></button>
                </div>
                <DataTable slug="users" columns={columns} rows={userRows}/>
                {open && <AddUserForm setOpen={setOpen} fetchUsers={fetchUsers()}/>}
            </div>
        </>

    );
};

export default Users;
