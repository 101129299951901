import {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import "./ResetPasswordScreen.scss";
import useDocumentTitle from "../../Title/useDocumentTitle";

const ResetPasswordScreen = ({ match }) => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    const history = useNavigate();

    useDocumentTitle(`Balkanflix - Reset Password`);

    useEffect(() => {
        if (localStorage.getItem("authToken")){
            history("/");
        }
    }, [history]);

    const resetPasswordHandler = async (e) => {
        e.preventDefault();

        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        if (password !== confirmPassword) {
            setPassword("");
            setConfirmPassword("");
            setTimeout(() => {
                setError("");
            }, 5000);
            return setError("Passwords don't match");
        }
        const currentUrl = window.location.href;
        const segments = currentUrl.split('/');
        const token = segments[segments.length - 1];
        try {

            const {data} = await axios.put(
                `https://balkanflix-server.vercel.app/api/auth/resetPassword/${token}`,
                {
                    password,
                },
                config
            );
            // Update the success state with a string message, not an object
            setSuccess(data.data);
            console.log(data.data);
        } catch (error) {
            alert(error.message)
            setError(error.response.data.error);
            setTimeout(() => {
                setError("");
            }, 5000);
        }
    };

    return (
        <div className="resetpassword-screen">
            <Link className="menu-return" to="/">{"<- GLAVNA STRANICA"}</Link>
                <div className="respass-logo">
                    <img src="/Balkanflix-removebg.png" alt="logo" height="70px" width="62px"/>
                    <p>Balkanflix</p>
                </div>
            <form
                onSubmit={resetPasswordHandler}
                className="resetpassword-screen__form"
            >
                <h3 className="resetpassword-screen__title">Promeni lozinku</h3>
                {error && <span className="error-message">{error} </span>}
                {success && (<span className="success-message">{success} <Link to="/login">Login</Link></span>)}
                <div className="form-group">
                    <label htmlFor="password">Nova lozinka:</label>
                    <input
                        type="password"
                        required
                        id="password"
                        placeholder="Unesi novu lozinku"
                        autoComplete="true"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="confirmpassword">Potvrdi novu lozinku:</label>
                    <input
                        type="password"
                        required
                        id="confirmpassword"
                        placeholder="Potvrdi novu lozinku"
                        autoComplete="true"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                </div>
                {success &&
                    <button disabled="true" type="submit" className="reset-pass__btn disabled-btn">
                        Resetuj lozinku
                    </button>
                }
                {!success &&
                    <button type="submit" className="reset-pass__btn">
                        Resetuj lozinku
                    </button>
                }
            </form>
        </div>
    );
};

export default ResetPasswordScreen;
