import {
    Area,
    AreaChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import "./bigChartBox.scss";
import axios from 'axios';
import { useEffect, useState } from "react";

const BigChartBox = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [exchangeRate, setExchangeRate] = useState(1);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const today = new Date();
                const endDate = today.toISOString().split('T')[0]; // Today's date
                today.setDate(today.getDate() - 29); // 30 days ago
                const startDate = today.toISOString().split('T')[0]; // 30 days ago

                // Fetch revenue data
                const revenueResponse = await axios.get(`https://balkanflix-server.vercel.app/api/auth/publisher-stats/1059036?group_by=date&sort_by=date&start_date=${startDate}&end_date=${endDate}`);

                // Fetch views data from Filemoon
                const viewsResponse = await axios.get(`https://filemoonapi.com/api/account/stats?key=46529qdxsb1y65ki1juu9&last=30`);
                const viewsData = viewsResponse.data.result;

                // Fetch exchange rate
                const exchangeRateResponse = await axios.get('https://api.exchangerate-api.com/v4/latest/USD'); // Replace with your chosen API URL
                const usdToRsd = exchangeRateResponse.data.rates.RSD;

                setExchangeRate(usdToRsd);

                // Assuming revenueResponse.data and viewsData are arrays of objects
                const formattedData = revenueResponse.data.map(item => {
                    const viewItem = viewsData.find(view => view.day === item.date);
                    return {
                        day: item.date,
                        zarada: (parseFloat(item.earnings) * usdToRsd).toFixed(2),
                        pregledi: viewItem ? viewItem.views : 0
                    };
                });

                setData(formattedData);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setError(error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const formatTooltip = (value, name) => {
        if (name === "zarada") {
            return `${value} RSD`;
        }
        return value;
    };

    return (
        <div className="bigChartBox">
            <h1 className="big_chart_title">Analitika Zarade i Pregleda</h1>
            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p>Error fetching data</p>
            ) : (
                <div className="chart">
                    <ResponsiveContainer width="98%" height={300}>
                        <AreaChart
                            data={data}
                            margin={{
                                top: -40,
                                right: 30,
                                left: 0,
                                bottom: 0,
                            }}
                        >
                            <XAxis
                                dataKey="day"
                                tickFormatter={(tick) => {
                                    const date = new Date(tick);
                                    return `${date.getDate()}/${date.getMonth() + 1}`;
                                }}
                            />
                            <YAxis />
                            <Tooltip formatter={formatTooltip} />
                            <Area
                                type="monotone"
                                dataKey="zarada"
                                stackId="2"
                                stroke="#ff7300"
                                fill="#ff7300"
                            />
                            <Area
                                type="monotone"
                                dataKey="pregledi"
                                stackId="1"
                                stroke="#82ca9d"
                                fill="#82ca9d"
                            />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            )}
        </div>
    );
};

export default BigChartBox;
