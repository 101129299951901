import React, { useState, useEffect } from 'react';
import SwiperCore from 'swiper';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Button from '../button/Button';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import './hero-slide.scss';
import 'swiper/css'
import 'swiper/css/autoplay'
import HeroSlideSkeleton from "./heroSlideSkeleton";

// Memoized HeroSlideItem component to prevent unnecessary re-renders
const HeroSlideItem = React.memo(props => {
    let navigate = useNavigate();
    const item = props.item;
    const background = item.poster;

    return (
        <div
            className={`hero-slide__item ${props.className}`}
            style={{ backgroundImage: `url(https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/${background})` }}
        >
            <div className="hero-slide__item__content container">
                <div className="hero-slide__item__content__info">
                    <h2 className="title">{item.title}</h2>
                    <div className="overview">{item.description}</div>
                    <div className="hero-btns">
                        <Button className="hero-btn" onClick={() => navigate(`/${item.title_params}`)}>
                            Gledajte
                        </Button>
                    </div>
                </div>
                <div className="hero-slide__item__content__poster">
                    <img src={`https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/${item.img}`} alt="hero-slide" loading="lazy" width="400px" height="600px"/>
                </div>
            </div>
        </div>
    );
});

const HeroSlide = () => {
    SwiperCore.use([Autoplay]);
    const [movieItems, setMovieItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // Loading state

    useEffect(() => {
        const fetchSeries = async () => {
            setIsLoading(true); // Start loading
            try {
                const { data } = await axios.get('https://balkanflix-server.vercel.app/api/content/seriesHero');
                setMovieItems(data.series.slice(0, 4)); // Assuming server provides sorted data
            } catch (error) {
                console.error("Error fetching data: ", error);
            } finally {
                setIsLoading(false); // End loading
            }
        };
        fetchSeries();
    }, []);

    // if (isLoading) {
    //     return (
    //         <div className="hero-slide">
    //             <Swiper
    //                 wrapperClass="wrapper"
    //                 modules={[Autoplay]}
    //                 grabCursor={true}
    //                 spaceBetween={0}
    //                 autoplay={{ delay: 5000 }}
    //                 slidesPerView={1}
    //             >
    //                 {
    //                     (
    //                         Array.from({ length: 4 }).map((_, index) => (
    //                         <SwiperSlide key={index}>
    //                             <HeroSlideSkeleton />
    //                         </SwiperSlide>
    //                     )
    //
    //                 ))}
    //             </Swiper>
    //         </div>
    //     );    }

    return (
        <div className="hero-slide">
            <Swiper
                wrapperClass="wrapper"
                modules={[Autoplay]}
                grabCursor={true}
                spaceBetween={0}
                autoplay={{delay: 5000}}
                slidesPerView={1}
            >
                <>
                    {isLoading ? (
                        // Display skeletons if data is loading
                        Array.from({ length: 4 }).map((_, index) => ( // Assuming you want 4 skeletons
                            <SwiperSlide key={index}>
                                <HeroSlideSkeleton />
                            </SwiperSlide>
                        ))
                    ) : (
                        // Display the actual content once data is loaded
                        movieItems.map((item, i) => (
                            <SwiperSlide key={i}>
                                {({isActive}) => (
                                    <HeroSlideItem item={item} className={`${isActive ? 'active' : ''}`}/>
                                )}
                            </SwiperSlide>
                        ))
                    )}
                </>

                {/*{*/}
                {/*    movieItems.map((item, i) => (*/}
                {/*        <SwiperSlide key={i}>*/}
                {/*            {({isActive}) => (*/}
                {/*                <HeroSlideItem item={item} className={`${isActive ? 'active' : ''}`}/>*/}
                {/*            )}*/}
                {/*        </SwiperSlide>*/}
                {/*    ))*/}
                {/*}*/}
            </Swiper>
        </div>
    );
}

export default HeroSlide;
