import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './mini-chat.scss';
import client, { databases, DATABASE_ID, COLLECTION_ID } from './appwriteConfig/appwriteConfig';
import { Query } from "appwrite";

const MiniChat = () => {
    const [messages, setMessages] = useState([]);
    const scrollRef = useRef(null);
    const navigate = useNavigate();
    const [shouldReconnect, setShouldReconnect] = useState(true);

    const username = localStorage.getItem('username');


    useEffect(() => {
        const getMessages = async () => {
            const res = await databases.listDocuments(
                DATABASE_ID,
                COLLECTION_ID,
                [
                    Query.orderAsc('$createdAt'),
                    Query.limit(10000)
                ]
            );
            setMessages(res.documents);
        };

        getMessages();

        let unsubscribe;

        const connectRealtime = () => {
            unsubscribe = client.subscribe(`databases.${DATABASE_ID}.collections.${COLLECTION_ID}.documents`, response => {
                if (response.events.includes("databases.*.collections.*.documents.*.create")) {
                    setMessages(prevState => [...prevState, response.payload]);
                }
            }, error => {
                console.error("Realtime subscription error:", error);
                if (shouldReconnect) {
                    setTimeout(connectRealtime, 5000); // try to reconnect every 5 seconds
                }
            });
        };

        connectRealtime();

        return () => {
            setShouldReconnect(false); // When component unmounts, prevent reconnection
            if (unsubscribe) unsubscribe();
        };
    }, []);


    useEffect(() => {
        const scrollContainer = scrollRef.current?.parentNode;
        if (scrollContainer) {
            scrollContainer.scrollTop = scrollContainer.scrollHeight;
        }
    }, [messages]);

    const formatTime = dateStr => {
        const date = new Date(dateStr);
        return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false });
    };

    return (
        <div className="mini-chat">
            <div className="mini-messages">
                {messages.map(message => (
                    <div className={message.username === username ? "message-my" : "mini-message"} key={message.$id}>
                        <div className="name">
                            <img src={message.pfp} alt="PFP"/>
                            <h3>{message.username}</h3>
                            <p>{formatTime(message.$createdAt)}</p>
                        </div>
                        <div className="message-holder">
                            <p>{message.body}</p>
                        </div>
                    </div>
                ))}
                <div ref={scrollRef} style={{height: '1px'}}></div>
            </div>
            <button className="go-to-chat" onClick={() => navigate('/chat')}>
                Ćaskaj sa nama!
            </button>
        </div>
    );
};

export default MiniChat;
