import { useEffect, useState } from "react";
import axios from "axios";
import "./addUserForm.scss";

const AddUserForm = ({ setOpen, fetchUsers }) => {
    const [formData, setFormData] = useState({
        username: "",
        email: "",
        password: ""
    });
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleAddUser = async () => {
        setLoading(true);
        try {
            const response = await axios.post("https://balkanflix-server.vercel.app/api/auth/addUserForm", formData, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 201) {
                console.log("User created successfully");
                setOpen(false);
                fetchUsers();
            } else {
                console.error("Failed to create user");
            }
        } catch (error) {
            if (error.response) {
                setError(error.response.data.error);
            } else if (error.request) {
                setError("Request failed. Please try again.");
            } else {
                setError("An error occurred. Please try again later.");
            }

            setTimeout(() => {
                setError('');
            }, 5000);
        }
        setLoading(false);
    }

    useEffect(() => {
        const handleOutsideClick = (event) => {
            const modalContent = document.querySelector(".add-user-form");

            // Check if the click is outside the modal
            if (modalContent && !modalContent.contains(event.target)) {
                setOpen(false);
            }
        };

        // Add event listener when the component mounts
        document.addEventListener("mousedown", handleOutsideClick);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [setOpen]);

    return (
        <>
            {loading && (
                <div className="loading-modal">
                    <div className="loading-bg">
                        <div className="loading-spinner"></div>
                        <h1>Adding a user...</h1>
                    </div>
                </div>
            )}
            <div className="modal-overlay">
                <div className="modal-content">
                    <div className="add-user-form">
                        <UserPlusIcon/>
                        <h1>Add New User</h1>
                        <h5>Enter the details of the new user below</h5>
                        <div className="add-user-form__inputs">
                            <div className="add-user-form__input">
                                <label htmlFor="username">Username</label>
                                <input
                                    placeholder="Enter username"
                                    type="text"
                                    name="username"
                                    id="username"
                                    value={formData.username}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="add-user-form__input">
                                <label htmlFor="email">Email</label>
                                <input
                                    placeholder="Enter email"
                                    type="email"
                                    name="email"
                                    id="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="add-user-form__input">
                                <label htmlFor="password">Password</label>
                                <input
                                    placeholder="Enter password"
                                    type="password"
                                    name="password"
                                    id="password"
                                    value={formData.password}
                                    onChange={handleInputChange}
                                />
                            </div>

                        </div>

                        <button onClick={handleAddUser} className="add-user-form__btn">
                            Add User
                        </button>
                        {error && <span className="error-message">{error}</span>}
                    </div>
                </div>
            </div>
        </>
    );
};

function UserPlusIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"/>
            <circle cx="9" cy="7" r="4"/>
            <line x1="19" x2="19" y1="8" y2="14"/>
            <line x1="22" x2="16" y1="11" y2="11"/>
        </svg>
    );
}

export default AddUserForm;
