// HeroSlideSkeleton.jsx
import React from 'react';
import './hero-slide-skeleton.scss'; // Ensure this SCSS file is correctly referenced

const HeroSlideSkeleton = () => (
    <div className="hero-slide-skeleton">
        <div className="hero-image-skeleton"></div>
        <div className="hero-skeleton-content container">
            <div className="hero-title-skeleton"></div>
            <div className="hero-subtitle-skeleton"></div>
            <div className="hero-title-skeleton"></div> {/* Assuming you meant to repeat this for effect */}
        </div>
    </div>
);

export default HeroSlideSkeleton;
