import MainHeader from "./components/header/Header";
import MainFooter from "./components/footer/Footer";
import {Outlet} from "react-router-dom";

const MainLayout = () => {
    return (
        <div className="">
            <MainHeader/>
            <div className="">
                <div className="">
                    <Outlet/>
                </div>
            </div>
            <MainFooter/>
        </div>
    )
}

export default MainLayout;