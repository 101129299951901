import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useParams } from 'react-router-dom';
import MovieCard from '../../../Series/Card/card';
import './movie-grid.scss';
import { GoArrowSwitch } from "react-icons/go";

const MovieGrid = props => {

    const [items, setItems] = useState([]);
    const [allItems, setAllItems] = useState([]);
    const [showFilter, setShowFilter] = useState(false);
    const [filters, setFilters] = useState({
        genre: '',
        studio: '',
        status: '',
        type: ''
    });

    const [sortBy, setSortBy] = useState(''); // Inicijalno nije izabrano sortiranje
    const { keyword } = useParams();

    useEffect(() => {
        const getListFromBackend = async () => {
            const sortParam = props.category; // 'popular', 'newest', or 'rated'

            try {
                let response;
                if (keyword) {
                    response = await axios.get(`https://balkanflix-server.vercel.app/api/content/search?query=${keyword}`);
                } else {
                    response = await axios.get(`https://balkanflix-server.vercel.app/api/content/seriesGrid?sort=${sortParam}`);
                }
                setItems(response.data.series);
                setAllItems(response.data.series); // Store the original dataset
            } catch (error) {
                console.error("Error fetching series:", error);
            }
        };
        getListFromBackend();
    }, [keyword, props.category]);

    const toggleFilter = () => {
        setShowFilter(!showFilter);
    };

    const applyFilters = () => {
        let filteredItems = [...allItems]; // Start with the full, unmodified dataset

        if (filters.genre) {
            filteredItems = filteredItems.filter(item =>
                item.genre && item.genre.includes(filters.genre)
            );
        }

        if (filters.studio) {
            filteredItems = filteredItems.filter(item =>
                item.studio && item.studio.trim().toLowerCase() === filters.studio.toLowerCase()
            );
        }

        if (filters.status) {
            filteredItems = filteredItems.filter(item =>
                item.status && item.status.toLowerCase() === filters.status.toLowerCase()
            );
        }

        // Apply sorting
        switch (sortBy) {
            case 'pregledi':
                filteredItems.sort((a, b) => b.totalViews - a.totalViews);
                break;
            case 'datum':
                filteredItems.sort((a, b) => new Date(b.date_sorted) - new Date(a.date_sorted));
                break;
            case 'ocena':
                filteredItems.sort((a, b) => b.MAL_ocena - a.MAL_ocena);
                break;
            default:
                break;
        }

        setItems(filteredItems); // Update the items state with the filtered and sorted dataset
    };


    const handleSortChange = (e) => {
        setSortBy(e.target.value);
    };

    return (
        <>
            {!props.category && (
                <div className="toolbar">
                    <button onClick={toggleFilter}>Filter <GoArrowSwitch/></button>
                </div>
            )}

            {showFilter && (
                <div className="filter">
                    <select value={filters.genre} onChange={e => setFilters({...filters, genre: e.target.value})}>
                        <option value="" className="nonopt">Žanr</option>
                        <option value="Seinen">Seinen</option>
                        <option value="Shoujo">Shoujo</option>
                        <option value="Shounen">Shounen</option>
                        <option value="Josei">Josei</option>
                        <option value="Horor">Horor</option>
                        <option value="Komedija">Komedija</option>
                        <option value="Akcija">Akcija</option>
                        <option value="Avantura">Avantura</option>
                        <option value="Drama">Drama</option>
                        <option value="Romantika">Romantika</option>
                        <option value="Fantazija">Fantazija</option>
                        <option value="Misterija">Misterija</option>
                        <option value="Naučna fantastika">Naučna fantastika</option>
                        <option value="Triler">Triler</option>
                        <option value="Mecha">Mecha</option>
                        <option value="Slice of Life">Slice of Life</option>
                        <option value="Škola">Škola</option>
                        <option value="Magija">Magija</option>
                        <option value="Supermoći">Supermoći</option>
                        <option value="Sportski">Sportski</option>
                        <option value="Istorijski">Istorijski</option>
                        <option value="Psihološki">Psihološki</option>
                        <option value="Muzički">Muzički</option>
                    </select>
                    <select value={filters.studio} onChange={e => setFilters({...filters, studio: e.target.value})}>
                        <option value="" className="nonopt">Produkcija</option>
                        <option value="Bones">Bones</option>
                        <option value="Pierrot">Pierrot</option>
                        <option value="A-1 Pictures">A1 Pictures</option>
                        <option value="Mappa">MAPPA</option>
                        <option value="Madhouse">Madhouse</option>
                        <option value="Gallop">Gallop</option>
                        <option value="Kyoto Animation">Kyoto Animation</option>
                        <option value="Studio Ghibli">Studio Ghibli</option>
                        <option value="Toei Animation">Toei Animation</option>
                        <option value="Sunrise">Sunrise</option>
                        <option value="Wit Studio">Wit Studio</option>
                        <option value="Ufotable">Ufotable</option>
                        <option value="Production I.G">Production I.G</option>
                        <option value="Bee Train">Bee Train</option>
                        <option value="Trigger">Trigger</option>
                        <option value="Liden Films">Liden Films</option>
                        <option value="Shaft">Shaft</option>
                        <option value="Gainax">Gainax</option>
                        <option value="TMS Entertainment">TMS Entertainment</option>
                        <option value="Nippon Animation">Nippon Animation</option>
                        <option value="Gonzo">Gonzo</option>
                        <option value="GEMBA">GEMBA</option>
                        <option value="ENGI">ENGI</option>
                        <option value="Liden Films">GONZO</option>
                        <option value="Eight Bit">Eight Bit</option>
                        <option value="White Fox">White Fox</option>
                        <option value="J.C. Staff">J.C. Staff</option>
                        <option value="P.A. Works">P.A. Works</option>
                        <option value="E&H Production">E&H Production</option>
                        <option value="CloverWorks">CloverWorks</option>
                        <option value="Xebec">Xebec</option>
                        <option value="Satelight">Satelight</option>
                        <option value="Dugout">Dugout</option>
                        <option value="8bit">8bit</option>
                        <option value="Deen">Deen</option>
                        <option value="Shuka">Shuka</option>
                        <option value="Manglobe">Manglobe</option>
                        <option value="Studio 4°C">Studio 4°C</option>
                        <option value="Arms">Arms</option>
                    </select>
                    <select value={filters.status} onChange={e => setFilters({...filters, status: e.target.value})}>
                        <option value="" className="nonopt">Status</option>
                        <option value="Uskoro">Uskoro</option>
                        <option value="Emituje se">Emituje se</option>
                        <option value="završeno">Završeno</option>
                    </select>
                    <select value={filters.type} onChange={e => setFilters({...filters, type: e.target.value})}>
                        <option value="" className="nonopt">Tip</option>
                        <option value="serija">Serija</option>
                        <option value="film">Film</option>
                    </select>
                    <select value={sortBy} onChange={handleSortChange}>
                        <option value="" className="nonopt">Sortiraj po</option>
                        <option value="pregledi">Pregledima</option>
                        <option value="datum">Datumu</option>
                        <option value="ocena">Oceni</option>
                    </select>
                    <button onClick={applyFilters}>Primeni</button>
                </div>
            )}
            <div className="movie-grid">
                {
                    items.map((item, i) => <MovieCard item={item} key={i}/>)
                }
            </div>
        </>
    );
}

export default MovieGrid;