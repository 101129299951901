import { useEffect, useState } from "react";
import axios from "axios";
import "./addSeriesForm.css";

const AddSeriesForm = ({ setOpen, fetchUsers }) => {

    const [formData, setFormData] = useState({
        title: "",
        title_params: "",
        date: "",
        date_sorted: "",
        ep: "",
        MAL_ocena: "",
        img: "",
        img_two: "",
        poster: "",
        status: "",
        genre:"",
        description: "",
        studio: ""
    });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleAddSeries = async () => {
        setLoading(true);
        try {
            const response = await axios.post("https://balkanflix-server.vercel.app/api/content/addSeriesForm", formData, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 201) {
                console.log("Series created successfully");
                setOpen(false);
                fetchUsers();
            } else {
                console.error("Failed to create");
            }
        } catch (error) {
            if (error.response) {
                setError(error.response.data.error);
            } else if (error.request) {
                setError("Request failed. Please try again.");
            } else {
                setError("An error occurred. Please try again later.");
            }

            setTimeout(() => {
                setError('');
            }, 5000);
        }
        setLoading(false);
    }

    useEffect(() => {
        const handleOutsideClick = (event) => {
            const modalContent = document.querySelector(".add-user-form");

            // Check if the click is outside the modal
            if (modalContent && !modalContent.contains(event.target)) {
                setOpen(false);
            }
        };

        // Add event listener when the component mounts
        document.addEventListener("mousedown", handleOutsideClick);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [setOpen]);

    return (
        <>
            {loading && (
                <div className="loading-modal">
                    <div className="loading-bg">
                        <div className="loading-spinner"></div>
                        <h1>Adding series...</h1>
                    </div>
                </div>
            )}
            <div className="modal-overlay">
                <div className="modal-content">
                    <div className="add-user-form">
                        <UserPlusIcon/>
                        <h1>Add New Series</h1>
                        <h5>Enter the details of the new series below</h5>
                        <div className="add-user-form__inputs">
                            <div className="add-user-form__input">
                                <label htmlFor="title">Title</label>
                                <input
                                    placeholder="Enter title"
                                    type="text"
                                    name="title"
                                    id="title"
                                    value={formData.title}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="add-user-form__input">
                                <label htmlFor="title_params">Title Params</label>
                                <input
                                    placeholder="Enter title params"
                                    type="text"
                                    name="title_params"
                                    id="title_params"
                                    value={formData.title_params}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="add-user-form__input">
                                <label htmlFor="date">Date</label>
                                <input
                                    placeholder="Enter date ex. Jan 1, 2000"
                                    type="text"
                                    name="date"
                                    id="date"
                                    value={formData.date}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="add-user-form__input">
                                <label htmlFor="date_sorted">Date Sorted</label>
                                <input
                                    placeholder="Enter date ex. 2000-01-31"
                                    type="text"
                                    name="date_sorted"
                                    id="date_sorted"
                                    value={formData.date_sorted}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="add-user-form__input">
                                <label htmlFor="ep">Episodes</label>
                                <input
                                    placeholder="Number of episodes"
                                    type="text"
                                    name="ep"
                                    id="ep"
                                    value={formData.ep}
                                    onChange={handleInputChange}
                                    step="any"
                                />
                            </div>
                            <div className="add-user-form__input">
                                <label htmlFor="MAL_ocena">Rating</label>
                                <input
                                    placeholder="Number of episodes"
                                    type="number"
                                    name="MAL_ocena"
                                    id="MAL_ocena"
                                    value={formData.MAL_ocena}
                                    onChange={handleInputChange}
                                    step="any"
                                />
                            </div>
                            <div className="add-user-form__input">
                                <label htmlFor="status">Status</label>
                                <input
                                    placeholder="Enter status"
                                    type="text"
                                    name="status"
                                    id="status"
                                    value={formData.status}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="add-user-form__input">
                                <label htmlFor="genre">Genres</label>
                                <input
                                    placeholder="Enter genres"
                                    type="text"
                                    name="genre"
                                    id="genre"
                                    value={formData.genre}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="add-user-form__input">
                                <label htmlFor="img">IMG</label>
                                <input
                                    placeholder="Enter first image"
                                    type="text"
                                    name="img"
                                    id="img"
                                    value={formData.img}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="add-user-form__input">
                                <label htmlFor="img_two">IMG2</label>
                                <input
                                    placeholder="Enter second image"
                                    type="text"
                                    name="img_two"
                                    id="img_two"
                                    value={formData.img_two}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="add-user-form__input">
                                <label htmlFor="poster">Backdrop</label>
                                <input
                                    placeholder="Enter backdrop image"
                                    type="text"
                                    name="poster"
                                    id="poster"
                                    value={formData.poster}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="add-user-form__input">
                                <label htmlFor="studio">Studio</label>
                                <input
                                    placeholder="Enter studio"
                                    type="text"
                                    name="studio"
                                    id="studio"
                                    value={formData.studio}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>

                        <div className="add-user-form__inputs-whole">


                            <div className="add-user-form__input">
                                <label htmlFor="description">Description</label>
                                <input
                                    placeholder="Enter description"
                                    type="text"
                                    name="description"
                                    id="description"
                                    value={formData.description}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>

                        <button onClick={handleAddSeries} className="add-user-form__btn">
                            Add Series
                        </button>
                        {error && <span className="error-message">{error}</span>}
                    </div>
                </div>
            </div>
        </>
    );
};

function UserPlusIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"/>
            <circle cx="9" cy="7" r="4"/>
            <line x1="19" x2="19" y1="8" y2="14"/>
            <line x1="22" x2="16" y1="11" y2="11"/>
        </svg>
    );
}

export default AddSeriesForm;
