import {useCallback, useEffect, useState} from "react"
import "./productsdb.scss"
import DataTable from "../components/productsTable/ProductsTable"
// import Add from "../components/addUser/Add"
import AddSeriesForm from "../../../components/Buttons/addSeriesForm/addSeriesForm";
import axios from "axios";
import AddUserBtn from "../../Buttons/addUserBtn/addUser";

const Products = () => {
    const [open, setOpen] = useState(false);
    const [productRows, setProductRows] = useState([]);


    const columns = [
        { field: "id", headerName: "ID", width: 90 },
        {
            field: "img",
            headerName: "Image",
            width: 100,
            renderCell: params => {
                return <img src={params.row.img || "/noavatar.png"} alt="avatar" />
            }
        },
        {
            field: "title",
            type: "string",
            headerName: "Title",
            width: 280
        },
        {
            field: "views",
            type: "string",
            headerName: "Views Today",
            width: 120
        },
        {
            field: "total",
            type: "string",
            headerName: "Total",
            width: 120
        },
        {
            field: "ep",
            type: "string",
            headerName: "Ep",
            width: 120
        },
        {
            field: "date",
            headerName: "Date",
            type: "Date",
            width: 130
        },
        {
            field: "rating",
            headerName: "Rating",
            width: 200,
            type: "string"
        }
    ];

    const fetchProducts = useCallback(async () => {
        try {
            const res = await axios.get('https://balkanflix-server.vercel.app/api/content/series');
            const products = res.data.series.map(product => ({
                ...product,
                id: product._id,
                total: product.totalViews,
                views: product.totalViewsToday,
                rating: product.MAL_ocena + "⭐"
            }));
            setProductRows(products);
        } catch (error) {
            console.error("Failed to fetch users", error);
        }
    }, []);

    const toggleModal = () => {
        setOpen(!open);
    };

    useEffect(() => {
        fetchProducts();
    }, [fetchProducts]);

    return (
        <div className="products">
            <div className="info">
                <h1>Products</h1>
                <AddUserBtn product="Series" onClick={toggleModal}/>
                {/*<button onClick={() => setOpen(true)}>Add New Products</button>*/}
            </div>
            <DataTable slug="products" columns={columns} rows={productRows}/>
            {open && <AddSeriesForm setOpen={setOpen} fetchUsers={fetchProducts()}/>}
        </div>
    )
}

export default Products
