// CardSkeleton.jsx
import React from 'react';
import './card-skeleton.scss'; // Assuming you have a separate CSS file for the skeleton

const CardSkeleton = () => (
    <div className="card-skeleton">
        <div className="image-skeleton"></div>
        <div className="text-skeleton title-skeleton"></div>
        <div className="text-skeleton subtitle-skeleton"></div>
    </div>
);

export default CardSkeleton;
