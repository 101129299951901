import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateDashboard = () => {
    const isAdmin = localStorage.getItem("isAdmin");

    // Check if isAdmin is explicitly the string "false"
    if (isAdmin === "false" || !isAdmin) {
        return <Navigate to="/login" />;
    }

    return <Outlet />;
};

export default PrivateDashboard;