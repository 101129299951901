import { SwiperSlide, Swiper } from 'swiper/react';
import React, {Suspense, useState, useEffect } from 'react';

import axios from "axios";
import PropTypes from 'prop-types';
const MovieCard = React.lazy(() => import('../../../Series/Card/card'));
import './movie-list.scss';
import CardSkeleton from "../../../Series/Card/cardSkeleton";

const MovieList = props => {

    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchSeries = async () => {
            setLoading(true);
            try {
                const sortParam = props.type; // 'popular', 'newest', or 'rated'
                const url = `https://balkanflix-server.vercel.app/api/content/seriesList?sort=${sortParam}`;
                const { data } = await axios.get(url);

                setItems(data.series);
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
            setLoading(false);
        };

        fetchSeries();

    }, [props.type]); // Re-run only on props.type change

    return (
        <div className="movie-list">
            <Swiper
                grabCursor={true}
                spaceBetween={10}
                slidesPerView={'auto'}
            >
            <Suspense fallback={<div>Loading...</div>}>
                <>
                    {loading ? (
                        Array.from({ length: 10 }).map((_, index) => (
                            <SwiperSlide key={index}>
                                <CardSkeleton />
                            </SwiperSlide>
                        ))
                    ) : (
                        items.map((item, index) => (
                            <SwiperSlide key={index}>
                                <MovieCard item={item} />
                            </SwiperSlide>
                        ))
                    )}
                </>

            </Suspense>
            </Swiper>
        </div>
    );

}

MovieList.propTypes = {
    category: PropTypes.string,
    type: PropTypes.string.isRequired
}

export default MovieList;
