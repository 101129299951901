// src/pages/MainHome.js
import React, { Suspense, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { OutlineButton } from '../components/button/Button';
import HeroSlide from '../components/hero-slide/HeroSlide';
import MovieList from '../components/movie-list/MovieList';
import useDocumentTitle from '../../Title/useDocumentTitle';
import { category } from '../api/tmdbApi';
import Schedule from "../../Buttons/upComing/UpComing";
import TopUsersModal from "../components/top-users/top-users";
import axios from 'axios';
import Button from '../components/button/Button';
import { SwiperSlide, Swiper } from 'swiper/react';
import CardSkeleton from "../../Series/Card/cardSkeleton";
import { FaHandshakeSimple } from 'react-icons/fa6';

const MainHome = () => {
    useDocumentTitle('Balkanflix Anime - Pocetna - Gledaj anime online sa prevodom');

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [topUsers, setTopUsers] = useState([]);
    const [userPosition, setUserPosition] = useState(null);
    const username = localStorage.getItem("username");
    const [newEpisodes, setNewEpisodes] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchTopUsers = async () => {
            try {
                const response = await axios.get("https://balkanflix-server.vercel.app/api/auth/getTopUsersByEpisodesWatchedFull");
                const users = response.data.topUsers;
                setTopUsers(users.slice(0, 5));

                const currentUserId = 'currentUser'; // Replace with actual current user ID
                const currentUserIndex = users.findIndex(user => user._id === currentUserId);
                if (currentUserIndex >= 0) {
                    setUserPosition(currentUserIndex + 1);
                }
            } catch (error) {
                console.error('Error fetching top users:', error);
            }
        };

        fetchTopUsers();
    }, []);

    useEffect(() => {
        const fetchNewEpisodes = async () => {
            setLoading(true);
            try {
                const { data } = await axios.get('https://balkanflix-server.vercel.app/api/episode/newest');
                setNewEpisodes(data);
                console.log(data);
            } catch (error) {
                console.error('Error fetching new episodes:', error);
            }
            setLoading(false);
        };
        fetchNewEpisodes();
    }, []);

    const toggleModal = () => {
        setIsModalOpen(prev => !prev);
    };

    return (
        <>
            <HeroSlide />
            <div className="home-container">
                <div className="home-anime-list">
                    {/* New Episodes Section */}
                    <div className="section mb-3">
                        <div className="section__header mb-2">
                            <h2>Najnovije Epizode</h2>
                        </div>
                        <div className="movie-list">
                            <Swiper
                                grabCursor={true}                                    
                                spaceBetween={10}
                                slidesPerView={'auto'}
                            >
                                <Suspense fallback={<div>Loading...</div>}>
                                    <>
                                        {loading ? (
                                            Array.from({ length: 15 }).map((_, index) => (
                                                <SwiperSlide key={index}>
                                                    <CardSkeleton />
                                                </SwiperSlide>
                                            ))
                                        ) : (
                                            newEpisodes.map((episode) => (
                                                <SwiperSlide key={episode.ep}>
                                                    <Link target="_top" to={`/watch/${episode.title_params}/${episode.ep}`}>                                                            
                                                        <div className="movie-card" style={{ backgroundImage: `url(https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/${episode.img})` }}>
                                                            {episode.partner && (
                                                                <FaHandshakeSimple className="partner-icon" />
                                                            )}
                                                            <Button>
                                                                <p className="watch-btn">GLEDAJ</p>
                                                           </Button>
                                                        <div className="episode-number">Epizoda {episode.ep}</div>
                                                        </div>
                                                        <h3>{episode.title}</h3>
                                                    </Link>
                                                </SwiperSlide>
                                            ))
                                        )}
                                    </>
                                </Suspense>
                            </Swiper>
                        </div>
                    </div>
                    <div className="section mb-3">
                        <div className="section__header mb-2">
                            <h2>Popularno</h2>
                            <Link to="/category/popular">
                                <OutlineButton className="small">Više</OutlineButton>
                            </Link>
                        </div>
                        <MovieList category={category.popular} type="popular" />
                    </div>
                </div>
                <div className="anime-upcoming">
                    <Schedule />
                </div>
            </div>

            {/* Arrow button and modal */}
            <TopUsersModal
                isModalOpen={isModalOpen}
                toggleModal={toggleModal}
                topUsers={topUsers}
                username={username}
                userPosition={userPosition}
            />
        </>
    );
}

export default MainHome;