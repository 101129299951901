import React from 'react';
import { useParams } from 'react-router-dom';
import PageHeader from '../components/page-header/PageHeader';
import MovieGrid from '../components/movie-grid/MovieGrid';

const Catalog = () => {
    const { category } = useParams();

    const CATEGORY_NAMES = {
        popular: 'Najgledanije',
        trending: 'Najbolje ocenjeno',
        movie: 'Anime Filmovi',
        newest: 'Najnovije',
    };

    const categoryName = CATEGORY_NAMES[category] || 'Serijali';

    return (
        <>
            <PageHeader>
                {categoryName}
            </PageHeader>
            <div className="home_container">
                <div className="section mb-3">
                    <MovieGrid category={category}/>
                </div>
            </div>
        </>
    );
}

export default Catalog;