import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'

//Routing
import PrivateRoute from './components/routing/PrivateRoute';
import PrivateDashboard from './components/routing/PrivateDashboard';

//Screens
import LoginScreen from './components/Authentication/Login/LoginScreen';
import RegisterScreen from './components/Authentication/Register/RegisterScreen';
import ForgotPasswordScreen from './components/Authentication/ForgotPass/ForgotPasswordScreen';
import ResetPasswordScreen from './components/Authentication/ResetPass/ResetPasswordScreen';
// import SendVerificationEmail from './components/Authentication/Verification/SendVerificationEmail';
// import VerifyEmail from './components/Authentication/Verification/VerifyEmail';
//Dashboard
import Dashboard from './components/Dashboard/components/home/dashboard'
import Products from "./components/Dashboard/Products/productsdb";
import UsersPage from "./components/Dashboard/components/users/userspagedb";
import Layout from "./components/Dashboard/layout"

//MainScreen
import MainHome from "./components/Mainscreen/pages/Home";
import MainLayout from "./components/Mainscreen/mainLayout";
import Catalog from "./components/Mainscreen/pages/Catalog";
import AboutUs from "./components/AboutUs/aboutUs";

//Episode
import Episode from "./components/Series/Episode/episode"
import Details from "./components/Series/Details/details"

import UserPage from "./components/UserPage/userPage";

import Chat from './components/Chat/Chat';
import Forms from "./components/Dashboard/components/form/form";

import {useEffect} from "react";


//OAuth
import {GoogleOAuthProvider} from "@react-oauth/google";



const App = () => {

    const checkPermission = () => {
        if (!('serviceWorker' in navigator)) {
            throw new Error("No support for service worker!")
        }

        if (!('Notification' in window)) {
            throw new Error("No support for notification API");
        }

        if (!('PushManager' in window)) {
            throw new Error("No support for Push API")
        }
    }

    const registerSW = async () => {
        try {
            const registration = await navigator.serviceWorker.register('/sw.js');
            console.log('Service Worker registered with scope:', registration.scope);
        } catch (error) {
            console.error('Service Worker registration failed:', error);
        }
    }

    const requestNotificationPermission = async () => {
        const permission = await Notification.requestPermission();

        if (permission !== 'granted') {
            throw new Error("Notification permission not granted")
        }

    }

    const main = async () => {
        checkPermission()
        await requestNotificationPermission()
        await registerSW()
    }

    useEffect(() => {
        main().then(r => console.log);
    }, []);

    return (
        <GoogleOAuthProvider clientId="1011305876980-8hg4rjmefaq4r24ssrt1qplvqda1gbc3.apps.googleusercontent.com">
            <Router>
                <div className="app">

                    <Routes>
                        <Route path="/dashboard" element={<PrivateDashboard/>}>
                            <Route path="/dashboard" element={<Layout />}>
                                <Route index element={<Dashboard />} />
                                <Route path="users" element={<UsersPage />} />
                                <Route path="series" element={<Products />} />
                                <Route path="forms" element={<Forms />} />
                            </Route>
                        </Route>
                        <Route path="/" element={<MainLayout />}>
                            <Route index element={<MainHome />} />
                            {/*<Route path=":category/:id" element={<Detail />} />*/}
                            <Route path="/search/:keyword" element={<Catalog />} />
                            <Route path="/category/:category" element={<Catalog />} />
                            <Route path="/:title" element={<Details />} />
                            <Route path="/watch/:title/:ep" element={<Episode/>} />
                            <Route path="/o-nama" element={<AboutUs/>} />
                        </Route>
                        <Route path="/profil" element={<UserPage/>} />

                        <Route path="/login" element={<LoginScreen />} />
                        <Route path="/register" element={<RegisterScreen />} />
                        <Route path="/forgotpassword" element={<ForgotPasswordScreen />} />
                        <Route path="/passwordreset/:resetToken" element={< ResetPasswordScreen />} />
                        {/*<Route path="/sendVerificationEmail" element={<SendVerificationEmail />} />*/}
                        {/*<Route path="/verifyEmail/:verificationToken" element={< VerifyEmail />} />*/}
                        <Route path="/chat" element={<PrivateRoute />}>
                            <Route path="/chat" element={< Chat />} />
                        </Route>
                        {/*<Route path="/chat" element={<Chat />} />*/}
                    </Routes>
                </div>
        </Router>
    </GoogleOAuthProvider>
  );
}
export default App;

